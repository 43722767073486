import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ShelterPage from "./pages/shelter";
import "antd/dist/antd.css";
import "./resources/fonts/Geomanist/stylesheet.css";
import * as ReactGA from "react-ga";
import * as Sentry from "@sentry/react";
import CacheCleaner from "./Components/CacheCleaner/CacheCleaner";
import { RewriteFrames } from "@sentry/integrations";
import { isLocalStorageAvailable, SessionProvider } from "./libs/sessionProvider";
import { HttpClient } from "@sentry/integrations";
import NewPage from "./pages/new";

export const SESSION_TOKEN_KEY = "token_2";
export const GOOGLE_ANALYTCS_KEY = "UA-78077384-2";
export const getEnv = () => "default";

export const ENV = {
  default: {
    organization_url: process.env.REACT_APP_ORGANIZATION_URL,
    adopter_url: process.env.REACT_APP_ADOPTER_URL,
    api_url: process.env.REACT_APP_API_URL,
    api_service_url: process.env.REACT_APP_API_SERVICE_URL,
    system_api_key: process.env.REACT_APP_SYSTEM_API_KEY,
    stripe_key: process.env.REACT_APP_STRIPE_KEY,
  }
};



function App() {
  
  if(process.env.NODE_ENV !== 'development'){
    Sentry.init({
      dsn: "https://a762a372034d6a29ff11076e597064fd@o4506220446220288.ingest.us.sentry.io/4506349127598080",
      integrations: [new RewriteFrames(),new Sentry.BrowserTracing(),new HttpClient()],
      environment: process.env.REACT_APP_ENV_CODENAME,
      release: "legacy-web-iframe@2.5.13",
      
      ignoreErrors: ['SyntaxError', 'Failed to fetch', /Failed to fetch/],
      // Performance Monitoring
      tracesSampleRate: 1, // Capture 100% of the transactions
    }); 

    
  }

  ReactGA.initialize(GOOGLE_ANALYTCS_KEY, {
    gaOptions: { cookieFlags: "max-age=7200;secure;samesite=none" },
  });


  return (
    <BrowserRouter>
      <SessionProvider>
        {isLocalStorageAvailable() && <CacheCleaner />}
        <Routes>
          <Route path="/" element={<div>404</div>} />
          <Route path="/:shelter" element={<ShelterPage />} />
          <Route path="/compact/:shelter" element={<NewPage />} />
        </Routes>
      </SessionProvider>
    </BrowserRouter>
  );
}

export default App;
