
import styled from 'styled-components';

export const CheckboxScrollMenu = styled.div`
    display: flex !important;
    flex-direction: column !important;
    max-height: 250px;
    overflow-y: auto !important;
    padding-top: 5px;
    padding-bottom: 5px;

    h3 {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 16px;
        
        img {
            margin-right: 8px;
        }
    }
    
    .ant-checkbox-wrapper + h3 {
        margin-top: 24px;
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper{
        margin-left: 0px;
        margin-top: 16px;
    }
`;

export const ShelterPageStyle = styled.div`

    .SelectInput {
        width: 100%;
        border: 1px solid #C7CDD4;
        height:52px;
        border-radius: 8px;
        padding-left: 16px;
        outline: none;
    }

    .titleBox {
        margin-top: 16px;
        margin-bottom: 11px;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #1B1B47;
    }

    .box {
        margin-top: 16px;
        border-bottom: 2px dashed #ECEEF1;
        padding-bottom: 16px;
        .checkboxContainer{
            width: 150px;
        }
        .ant-checkbox + span{
            color: #1B1B47;
            font-size: 16px;
            font-weight: 500;
        }
        .ant-checkbox-inner{
            width: 24px;
            height: 24px;
            border-radius: 4px;
        }
        .ant-checkbox-inner::after{
            left: 32%;
        }
    }

    .searchButton{
        background-color: #FFF;
        padding-top: 16px;
        padding-bottom: 16px;
        z-index: 10;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .secondary{
            width: 163px;
            height: 52px;
            background: #FFE5EE;
            border-radius: 8px;
            color: #E52E6B;
            border: 0px;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-weight: 500;
        }

        .primary {
            width: 163px;
            height: 52px;
            background: #E52E6B;
            border-radius: 8px;
            color: #FFFFFF;
            border: 0px;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-weight: 500;
        }
    }

    .mobileActions {
        background-color: #FFF;
        padding-top: 16px;
        padding-bottom: 16px;
        z-index: 10;
        width: 100%;
        display: flex;
        justify-content: space-between;

        button {
            max-width: 163px;
            border-radius: 8px;
            border: 0px;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            cursor: pointer;
            height: 52px;
            width: 50%;
        }

        button + button {
            margin-left: 17px;
        }

        .secondary {
            background: #FFE5EE;
            color: #E52E6B;
        }

        .primary{
            background: #E52E6B;
            color: #FFFFFF;
        }
    }
`;
