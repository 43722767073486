import React, { FC } from 'react'
import { useClearCache } from 'react-clear-cache';
import { Spin } from 'antd';

interface Props {}

/**
* @author
* @function CacheCleaner
**/



const CacheCleaner:FC<Props> = (props) => {

  let att = false;

  const { isLatestVersion,emptyCacheStorage , loading } = useClearCache({ duration: 50000 } as any);

  if(!isLatestVersion && !att && !loading){
    att = true;
    setTimeout(function(){ emptyCacheStorage(""); }, 3000);
    //emptyCacheStorage("");
    console.log("update version")
    
  }

  return (
    <div>
        {!isLatestVersion && (
          <div style={{backgroundColor:"#FFF",position:"fixed",width:"100%",height:"100%",display:"flex",justifyContent:"center",alignItems:"center",zIndex:1000}}>
            <Spin/>
          </div>
        )}
    </div>
   )
 }

CacheCleaner.propTypes = {
// your expected props
}

CacheCleaner.defaultProps = {
// your default props
}
export default CacheCleaner
