import React, { useState, FC, useEffect } from 'react';
import { SessionContext } from './sessionContext'
import Fingerprint2 from "fingerprintjs2";
import axios from "axios";
import { ENV, getEnv, SESSION_TOKEN_KEY } from '../App';
import * as Sentry from "@sentry/react";
// ...

export function isLocalStorageAvailable() {
  var test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export const SessionProvider: FC = ({ children }) => {
  const [sessionKey, setSessionKey] = useState('');

  function getToken() {
    var options = {
      excludes: { canvas: true, webgl: true },
    };

    Fingerprint2.get(options, function (components) {
      var values = components.map(function (component) {
        return component.value;
      });
      var murmur = Fingerprint2.x64hash128(values.join(""), 31);

      let items = {};
      components.map((item) => {
        items[item.key] = item.value;
      });

      const options = {
        url: ENV[getEnv()].api_url + "/auth/session-request",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: { system_api_key: process.env.REACT_APP_SYSTEM_API_KEY, fingerprint: murmur, json: items },
      };

      axios(options as any).then((response) => {
        Sentry.addBreadcrumb({
          category: "session-request",
          data: response,
          level: "info",
        });
        //mutate(response.data.data);
        setSessionKey(response?.data?.data?.access_key);
        if (isLocalStorageAvailable()) {
          window.localStorage.setItem(SESSION_TOKEN_KEY, response?.data?.data?.access_key);
        }
        //document.location.reload();
      });
    });
  }



  useEffect(() => {
    if (isLocalStorageAvailable()) {
      const session_token = window.localStorage.getItem(SESSION_TOKEN_KEY) || ''

      if (session_token) {
        setSessionKey(session_token);
      } else {
        getToken();
      }

    } else {
      getToken();
    }

  }, [])
  return (
    <SessionContext.Provider
      value={{
        sessionKey,
        setSessionKey,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
