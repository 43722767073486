import React, { FC, useEffect, useState } from 'react'
import { DATA_TEST_NAME } from '../../utils/config';


interface Props extends React.HTMLAttributes<HTMLDivElement> {
  tracking_id: string
  data: any
  newLayout?: boolean
}

/**
* @author 
* @function Card
**/
/*
const PetSizeMap = {
  XS : "Extra small",
  S: "Small",
  M: "Medium",
  L: "Large",
  XL: "Extra large",
}*/

export const SexMap = {
  MALE: "Male",
  FEMALE: "Female",
  UNKNOWN: "Unknown"
}

const Card: FC<Props> = ({ data, tracking_id, ...props }) => {
  let name = "";
  let picture = "";
  let sex_key = "";
  //let size_key = "";
  let organizationPet = data?.organization_pet;
  let code = "";
  const [larguraJanela, setLarguraJanela] = useState(window.innerWidth);


  useEffect(() => {
    const atualizarLarguraJanela = () => {
      setLarguraJanela(window.innerWidth);
    };

    window.addEventListener('resize', atualizarLarguraJanela);

    return () => {
      window.removeEventListener('resize', atualizarLarguraJanela);
    };
  }, []);

  const tamanhoDaDiv = larguraJanela > 500 ? '350px' : `${larguraJanela / 2}px`;
  const tamanhoDaDivNewLayout = larguraJanela > 768 ? '350px' : `550px`;



  if (data?.organization_pet) {
    name = organizationPet.name;
    picture = organizationPet.picture;
    sex_key = organizationPet.sex_key;
    //size_key = organizationPet.size_key;
    code = organizationPet.code
  }

  const goToPet = () => {
    if (!organizationPet) return
    window.open(process.env.REACT_APP_ADOPTER_URL + "/pet/" + organizationPet.uuid + "?tracking=" + tracking_id, "_blank");
  }

  if (!picture) picture = "/pet-profile-pic.svg"

  const dataTestProp = (name: string) => props["data-testId"] && ({ "data-testId": name })

  return (
    <div
      style={{
        cursor: "pointer",
        position: "relative",
        borderRadius: "8px",
        height: props.newLayout ? tamanhoDaDivNewLayout : tamanhoDaDiv,
      }}
      className={!picture ? "skeleton" : ""}
      onClick={goToPet}
      {...props}
    >
      {(picture || name) && (
        <div

          style={{
            background:
              "linear-gradient(180deg,rgba(11,17,24,0) 56%,#13162b 94%)",
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            borderRadius: "8px",
          }}
        />
      )}
      <div
        {...dataTestProp("pet-picture")}
        style={{
          backgroundSize: "cover",
          backgroundImage: picture ? `url(${picture})` : "",
          height: props.newLayout ? tamanhoDaDivNewLayout : tamanhoDaDiv,
          borderRadius: "8px",
          backgroundPosition: "center", // This is now separate from background
        }}
      />
      {(picture || name) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            bottom: "0px",
            width: "100%",
            padding: "16px",
          }}
          {...dataTestProp("pet-info")}
        >
          <span {...dataTestProp("pet-name")} style={{ fontSize: "16px", fontWeight: 500, color: "#FFF" }}>
            {name}
          </span>
          <span
            style={{
              fontSize: "14px",
              fontWeight: 500,
              color: "rgba(255, 255, 255, 0.72)",
            }}
          >
            {SexMap[sex_key] && <><span {...dataTestProp("pet-sex")} >{SexMap[sex_key]}</span> · <span {...dataTestProp("pet-code")}>{code && code}</span></>}
          </span>
        </div>
      )}
    </div>
  );
};


Card.defaultProps = {
  // your default props
}
export default Card