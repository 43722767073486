/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { Row, Col, Checkbox, Input } from "antd";
import Card from "../../Components/Card";
import axios from "axios";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { ShelterPageStyle, CheckboxScrollMenu } from "./style";
import DropDownButton from "../../Components/DropDownButton";
import { SESSION_TOKEN_KEY, ENV, getEnv } from "../../App";
import * as ReactGA from "react-ga";
import { useSession } from "../../libs/sessionContext";
import { isLocalStorageAvailable } from "../../libs/sessionProvider";
import * as Sentry from "@sentry/react";
import { useBreakpoints } from 'react-breakpoints-hook';

interface OrganizationPet {
  query: string
  size_key?: string[] | null
  sex_key?: string[] | null
  age_key?: string[] | null
  specie_uuid?: string[] | null
  breed_uuid?: string[] | null
  characteristic_uuid?: string[] | null
  group_uuid?: string | string[] | null
  foster?: boolean
}

let organization_pet: OrganizationPet = {
  query: "",
  size_key: [],
  sex_key: [],
  age_key: [],
  foster: undefined
};

const NewPage: FC = (props) => {
  let location = useLocation();
  const { sessionKey } = useSession();

  let {
    shelter_uuid,
    g,
    p,
    t,
    al_show: rawAlShow,
    al: rawAl,
  } = qs.parse(location.search);


  const [orgPets, setOrgPets] = useState(organization_pet);
  const [filterAgain, setFilterAgain] = useState(false);
  const [data, mutate] = useState<Array<any>>([{}, {}, {}, {}]);

  const [trackerUUID, setTrackerUUID] = useState("");
  const [lastSearch, setLastSearch] = useState(0);
  const [loading, setLoading] = useState(false);

  const [rotacaoAtual, setRotacaoAtual] = useState<number>(0);
  const [showPets, setShowPets] = useState<any[]>([]);

  const refreshTime = window.innerWidth <= 768 ? 15000 : 30000;

  useEffect(() => {
    const interval = setInterval(() => {
      setRotacaoAtual((prevRotacao) => (prevRotacao + (window.innerWidth <= 768 ? 1 : 4)) % data.length);
    }, refreshTime);

    return () => clearInterval(interval);
  }, [data.length]);

  useEffect(() => {

    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const itemsTime = windowWidth <= 768 ? 1 : 4;

      let indicesMostrados: any[] = [];
      for (let i = 0; i < itemsTime; i++) {
        const indice = (rotacaoAtual + i) % data.length;
        indicesMostrados.push(data[indice]);
      }

      if (indicesMostrados.length < itemsTime) {
        indicesMostrados = [
          ...indicesMostrados,
          ...data.slice(0, itemsTime - indicesMostrados.length),
        ];
      }

      setShowPets(indicesMostrados);
    }
    handleResize()
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [rotacaoAtual, data]);

  const baseUrl = ENV[getEnv()].api_url;

  const [userInteraction, setUserInteraction] = useState<boolean>(false);

  let { xs, lg } = useBreakpoints({
    xs: { min: 0, max: 1100 },
    lg: { min: 1100, max: null },
  });

  ReactGA.pageview(window.location.pathname + window.location.search);

  if (t === "true") {
    if (document?.body?.style) document.body.style.backgroundColor = "transparent";
  }

  // document.body.style.overflowX = "hidden";
  // document.body.style.overflowY = "hidden";

  function verifyCode(result) {
    const code = result?.data?.status;

    if (
      code === 5208 ||
      code === 5202 ||
      code === 5209 ||
      code === 5201 ||
      code === 5203 ||
      code === 5205
    ) {
      if (isLocalStorageAvailable()) {
        window.localStorage.setItem(SESSION_TOKEN_KEY, "");
        //emptyCacheStorage("");
      }
      window.location.reload();
    }
  }


  useEffect(() => {
    const doc = (document as any).getElementById("root");

    if (doc) window?.parent?.postMessage(doc?.clientHeight, "*");
    setTimeout(function () {
      if (doc) window?.parent?.postMessage(doc?.clientHeight, "*");
    }, 500);
    setTimeout(function () {
      if (doc) window?.parent?.postMessage(doc?.clientHeight, "*");
    }, 1000);
    setTimeout(function () {
      if (doc) window?.parent?.postMessage(doc?.clientHeight, "*");
    }, 2000);
  }, [lastSearch]);

  function Filter(reset?: boolean) {
    setUserInteraction(true);

    if (!sessionKey) {
      return;
    }

    axios.defaults.headers.common = { Authorization: `Bearer ${sessionKey}` };

    let data = {
      limit: 12,
      organization_pet: orgPets,
      shelter_uuid: shelter_uuid,
      origin_key: "IFRAME",
      user_interaction: userInteraction,
    };

    const options = {
      url: baseUrl + "/pet/find",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: data,
    };

    axios(options as any).then((response) => {
      Sentry.addBreadcrumb({
        category: "pet/find",
        data: response,
        level: "info",
      });
      verifyCode(response);
      setTrackerUUID(response?.data?.data?.tracker_pet_find?.uuid);
      mutate(response?.data?.data?.result);
      setTimeout(function () {
        window.parent.postMessage((document as any).getElementById("root")?.clientHeight, "*");
      }, 500);
    });

  }

  useEffect(() => {
    if (!shelter_uuid) return;
    Filter();

    window.parent.postMessage((document as any).getElementById("root")?.clientHeight, "*");
  }, [mutate,sessionKey]);


  useEffect(() => {
    if (filterAgain) {
      Filter(false);
      setFilterAgain(false);
    }
  }, [filterAgain, orgPets])

  const itemsToShow = data.slice(rotacaoAtual, rotacaoAtual + (window.innerWidth <= 768 ? 1 : 4));

  return (
    <ShelterPageStyle style={{
      display: "flex",
      justifyContent: "center",
      width: "100%"
    }}>
      <div style={{
        padding: p ? Number(p) : 16,
        width: "100%"
      }}>
         <Row gutter={[g ? Number(g) : 16, g ? Number(g) : 16]}>
          {shelter_uuid && showPets?.map((item, index) => {
            return (
              <Col key={"colp" + index} xs={24} lg={6}>
                <Card newLayout={true} data-testId={`container-pet-card-${index}`} tracking_id={trackerUUID} data={item} />
              </Col>
            );
          })}

          {loading &&
            [{}, {}, {}, {}].map((item, index) => {
              return (
                <Col key={"colp" + index} xs={24} lg={6}>
                  <Card newLayout={true} tracking_id={""} data={item} />
                </Col>
              );
            })}
        </Row>
        {(data?.length === 0 || !shelter_uuid) && (
          <div style={{
            width: "100%",
            minHeight: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column"
            }}>
              <div style={{
                fontWeight: 500,
                fontSize: "18px",
                color: "rgb(36, 55, 78)",
                textAlign: "center"
              }}>
                We didn't find any pet results for this location.
              </div>
            </div>
          </div>
        )}
      </div>
    </ShelterPageStyle>
  );
};


export default NewPage;
