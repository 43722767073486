import React, { FC, useState } from 'react'
import styled from 'styled-components';
import { Menu, Dropdown } from 'antd';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    title: string
    width: string
    hasFilter?: boolean
    onApply: () => void
    onClear: () => void
}

/**
* @author
* @function DropDownButton


**/
const Button = styled.button`
    border: 0px;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    padding: 9px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;

    &.primary {
        background: #E52E6B;
        color: #FFFFFF;
    }

    &.secondary {
        background: #FFE5EE;
        color: #E52E6B;
    }

    &.clean {
        color: #464B6F;
        background: transparent;
    }
`;

const DropdownStyle = styled(Dropdown)`
    display:flex;
    justify-content:space-between;
    .searchButton{
        background-color:#FFF;
        padding-top:16px;
        padding-bottom:16px;
        z-index:10;
        width:100%;
        display: flex;
        justify-content: space-between;
    }
`;


const DropDownButton: FC<Props> = (props) => {

    const [open, setOpen] = useState(false);

    const handleMenuClick = e => {

    };
    const handleVisibleChange = flag => {
        setOpen(flag);

    };
    const dataId = props["data-testId"].split("-")
    const prefix = dataId.length > 1 ? dataId[1] : ""
    return (
        <DropdownStyle
            overlay={
                <Menu onClick={handleMenuClick} data-testId={`${props["data-testId"]}-menu`}>
                    <Menu.Item >
                        <div style={{
                            width: props.width,
                            padding: "8px"
                        }}>
                            {props.children}
                        </div>
                    </Menu.Item>
                    <div
                        data-testId={`actions`}
                        style={{
                            padding: "24px 16px",
                            display: "flex",
                            justifyContent: "space-between"
                        }} className="searchButton">
                            <Button data-testId={`${prefix}-action-clear`} type="submit" className="clean" onClick={() => { props.onClear(); handleVisibleChange(false); }}>Clear</Button>
                        <Button data-testId={`${prefix}-action-apply`} className="primary" onClick={() => { props.onApply(); handleVisibleChange(false) }}>Apply</Button>
                    </div>
                </Menu>
            }
            trigger={['click']} onVisibleChange={handleVisibleChange} visible={open}
        >
            <button data-testId={`${props["data-testId"]}`} className="filterButton" style={{ cursor: 'pointer' }} onClick={e => e.preventDefault()}>{props.title} {props.hasFilter ? <div style={{
                background: "#E52E6B",
                width: "8px",
                height: "8px",
                marginLeft: "4px",
                borderRadius: "100%"
            }} /> : null}</button>
        </DropdownStyle>
    )
}

DropDownButton.propTypes = {
    // your expected props
}

DropDownButton.defaultProps = {
    // your default props
}
export default DropDownButton
