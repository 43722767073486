/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { Row, Col, Checkbox, Input } from "antd";
import Card from "../../Components/Card";
import axios from "axios";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { ShelterPageStyle, CheckboxScrollMenu } from "./style";
import DropDownButton from "../../Components/DropDownButton";
import { SESSION_TOKEN_KEY, ENV, getEnv } from "../../App";
import * as ReactGA from "react-ga";
import { useSession } from "../../libs/sessionContext";
import { isLocalStorageAvailable } from "../../libs/sessionProvider";
import * as Sentry from "@sentry/react";
import { useBreakpoints } from 'react-breakpoints-hook';


interface Props { }

/**
 * @author
 * @function ShelterPage
 **/

interface Props { }

interface SpecieItem {
  uuid: string
  total_breeds: number
  name: string
}

interface MistItem {
  key: string
  name: string
}

interface CharacteristicItem {
  uuid: string
  name: string
}

interface CharacteristicGroupItem extends CharacteristicItem {
  characteristics: CharacteristicItem[]
}

interface OrganizationPet {
  query: string
  size_key?: string[] | null
  sex_key?: string[] | null
  age_key?: string[] | null
  specie_uuid?: string[] | null
  breed_uuid?: string[] | null
  characteristic_uuid?: string[] | null
  group_uuid?: string | string[] | null
  foster?: boolean
}

let organization_pet: OrganizationPet = {
  query: "",
  size_key: [],
  sex_key: [],
  age_key: [],
  foster: undefined
};

const ShelterPage: FC = (props) => {
  let location = useLocation();
  const { sessionKey } = useSession();

  let {
    shelter_uuid,
    g,
    p,
    t,
    nb,
    specie_uuid,
    group_uuid,
    nl,
    al_show: rawAlShow,
    al: rawAl,
    branch_uuid,
    age,
    size,
    sex,
  } = qs.parse(location.search);

  const characteristicsGroupColors = {
    medical: '#30A8FF',
    behavior: '#FF8B00',
    environment: '#20A383',
  }

  const al = rawAl ? rawAl === 'true' ? true : false : undefined
  const al_show = rawAlShow === 'true' ? true : false

  const [selectedSpecie, setSelectedSpecie] = useState<string[]>([]);
  const [selectedSex, setSelectedSex] = useState<string[]>([]);
  const [selectedAge, setSelectedAge] = useState<string[]>([]);
  const [selectedSize, setSelectedSize] = useState<string[]>([]);
  const [fosterFilter, setFosterFilter] = useState<boolean | undefined>(al);

  const [selectedGroup, setSelectedGroup] = useState<{ uuid?: string, label?: string } | undefined>();
  const [selectedBreed, setSelectedBreed] = useState<string[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<string[]>([]);
  const [selectedCharacteristics, setSelectedCharacteristics] = useState<string[]>([]);
  const [orgPets, setOrgPets] = useState(organization_pet);
  const [filterAgain, setFilterAgain] = useState(false);
  const [ageKey, setAgeKey] = useState<string[]>([]);
  const [sexKey, setSexKey] = useState<string[]>([]);
  const [sizeKey, setSizeKey] = useState<string[]>([]);
  const [data, mutate] = useState<Array<any>>([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);

  const [trackerUUID, setTrackerUUID] = useState("");
  const [lastSearch, setLastSearch] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [branchList, setBranchList] = useState<Array<any>>([]);
  const [groupList, setgrouplist] = useState([]);
  const [speciesList, setSpecielist] = useState<Array<SpecieItem>>([]);
  const [breedList, setBreedList] = useState<Array<any>>([]);
  const [sexList, setSexList] = useState<Array<MistItem>>([]);
  const [ageList, setAgeList] = useState<Array<MistItem>>([]);
  const [sizeList, setSizeList] = useState<Array<MistItem>>([]);
  const [characteristicList, setCharacteristicList] = useState<Array<CharacteristicGroupItem>>([])
  const [BRANCH, SETBRANCH] = useState("");

  const baseUrl = ENV[getEnv()].api_url;
  const serviceUrl = ENV[getEnv()].api_service_url;

  //const { emptyCacheStorage } = useClearCache({ duration: 500000000 } as any);

  const [search, setSearch] = useState("");
  const [userInteraction, setUserInteraction] = useState<boolean>(false);

  let { xs, lg } = useBreakpoints({
    xs: { min: 0, max: 1100 },
    lg: { min: 1100, max: null },
  });

  ReactGA.pageview(window.location.pathname + window.location.search);

  if (t === "true") {
    if (document?.body?.style) document.body.style.backgroundColor = "transparent";
  }

  // document.body.style.overflowX = "hidden";
  // document.body.style.overflowY = "hidden";

  function verifyCode(result) {
    const code = result?.data?.status;

    if (
      code === 5208 ||
      code === 5202 ||
      code === 5209 ||
      code === 5201 ||
      code === 5203 ||
      code === 5205
    ) {
      if (isLocalStorageAvailable()) {
        window.localStorage.setItem(SESSION_TOKEN_KEY, "");
        //emptyCacheStorage("");
      }
      window.location.reload();
    }
  }

  function getSpecies(uuidGp) {
    if (!sessionKey) {
      return;
    }
    axios.defaults.headers.common = { Authorization: `Bearer ${sessionKey}` };

    let options = {
      url: serviceUrl + "/pet/specie",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      }, //4341531e-dcf3-44d0-86b6-9fe5ef64c4da
      data: { group_uuid: group_uuid ? group_uuid : uuidGp },
    };

    axios(options as any).then((response) => {
      //setSpecielist(response?.data?.data?.result);
    });
  }

  function getGroups() {
    if (!sessionKey) {
      return;
    }
    axios.defaults.headers.common = { Authorization: `Bearer ${sessionKey}` };

    const options = {
      url: serviceUrl + "/pet/group",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      }, //4341531e-dcf3-44d0-86b6-9fe5ef64c4da
      data: {},
    };

    axios(options as any).then((response) => {
      setgrouplist(response?.data?.data?.result.filter((item) => !item.highlight));
    });
  }

  function getBranch() {
    if (!sessionKey || !shelter_uuid) {
      if (!shelter_uuid) {
        Sentry.setContext("shelter_uuid not found", {
          url: window.location.href
        });
      }
      return;
    }

    axios.defaults.headers.common = { Authorization: `Bearer ${sessionKey}` };

    const options = {
      url: baseUrl + "/shelter/branch-list",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      }, //4341531e-dcf3-44d0-86b6-9fe5ef64c4da
      data: {
        shelter_uuid: shelter_uuid,
      },
    };

    axios(options as any).then((response) => {
      Sentry.addBreadcrumb({
        category: "shelter/branch-list",
        data: response,
        level: "info",
      });
      if (Array.isArray(response.data.data)) {
        setBranchList(response.data.data);
      } else {
        Sentry.setContext("branch-payload", response.data);
      }
      //setgrouplist(response?.data?.data?.result.filter(item => !item.highlight));
    });
  }

  useEffect(() => {
    if (group_uuid) setGroupByUuid(group_uuid);
  }, [groupList]);

  useEffect(() => {
    const doc = (document as any).getElementById("root");

    if (doc) window?.parent?.postMessage(doc?.clientHeight, "*");
    setTimeout(function () {
      if (doc) window?.parent?.postMessage(doc?.clientHeight, "*");
    }, 500);
    setTimeout(function () {
      if (doc) window?.parent?.postMessage(doc?.clientHeight, "*");
    }, 1000);
    setTimeout(function () {
      if (doc) window?.parent?.postMessage(doc?.clientHeight, "*");
    }, 2000);
  }, [lastSearch]);

  function Filter(reset?: boolean) {
    setUserInteraction(true);

    if (!sessionKey) {
      return;
    }

    axios.defaults.headers.common = { Authorization: `Bearer ${sessionKey}` };

    orgPets['specie_uuid'] = filterDefaultItems(selectedSpecie, specie_uuid as string, reset)
    orgPets['age_key'] = filterDefaultItems(selectedAge, age as string, reset)
    orgPets['sex_key'] = filterDefaultItems(selectedSex, sex as string, reset)
    orgPets['size_key'] = filterDefaultItems(selectedSize, size as string, reset)

    if (selectedGroup && !reset) {
      // orgPets["specie_uuid"] = null;
      orgPets["group_uuid"] = selectedGroup?.uuid;
    }

    if (group_uuid) {
      orgPets["group_uuid"] = group_uuid;
    }

    if (!reset) {
      orgPets["query"] = search;
    } else {
      orgPets["query"] = "";
    }


    if (!!selectedCharacteristics.length) {
      orgPets["characteristic_uuid"] = selectedCharacteristics
    } else {
      delete orgPets["characteristic_uuid"]
    }

    if (!!selectedBreed.length) {
      orgPets["breed_uuid"] = selectedBreed
    } else {
      delete orgPets["breed_uuid"]
    }




    let data = {
      limit: 12,
      organization_pet: orgPets,
      shelter_uuid: shelter_uuid,
      origin_key: "IFRAME",
      user_interaction: userInteraction,
    };

    data['branch_uuid'] = filterDefaultItems(selectedBranch, branch_uuid as string, reset)

    const options = {
      url: baseUrl + "/pet/find",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: data,
    };

    axios(options as any).then((response) => {
      Sentry.addBreadcrumb({
        category: "pet/find",
        data: response,
        level: "info",
      });
      verifyCode(response);
      setTrackerUUID(response?.data?.data?.tracker_pet_find?.uuid);
      setBreedList(response?.data?.data?.filters?.breed || []);
      setSpecielist(response?.data?.data?.filters?.specie || []);

      !!!characteristicList.length && setCharacteristicList(buildCharacteristics(response?.data?.data?.filters?.characteristic || []))
      sexList.length < response?.data?.data?.filters?.sex.length && setSexList(response?.data?.data?.filters?.sex || []);
      ageList.length < response?.data?.data?.filters?.age.length && setAgeList(response?.data?.data?.filters?.age || []);
      sizeList.length < response?.data?.data?.filters?.size.length && setSizeList(response?.data?.data?.filters?.size || []);

      mutate(response?.data?.data?.result);
      setLastSearch(response?.data?.data?.result?.length);
      setTimeout(function () {
        window.parent.postMessage((document as any).getElementById("root")?.clientHeight, "*");
      }, 500);
    });

  }

  function LoadMore() {
    setLoading(true);

    if (!sessionKey) {
      return;
    }

    axios.defaults.headers.common = { Authorization: `Bearer ${sessionKey}` };
    const options = {
      url: baseUrl + "/pet/find",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: {
        origin_key: "IFRAME",
        tracker_uuid: trackerUUID,
        offset: data.length,
        user_interaction: userInteraction,
      },
    };

    axios(options as any).then((response) => {
      verifyCode(response);
      setLoading(false);

      mutate(data.concat(response.data.data.result));

      setLastSearch(response?.data?.data?.result?.length);
      setTimeout(function () {
        window.parent.postMessage((document as any).getElementById("root")?.clientHeight, "*");
      }, 500);
    });
  }

  useEffect(() => {
    if (!shelter_uuid) return;
    getSpecies("");
    getGroups();
    getBranch();
    Filter();

    window.parent.postMessage((document as any).getElementById("root")?.clientHeight, "*");
  }, [mutate,sessionKey]);

  const removeSpecie = () => {
    delete organization_pet["specie_uuid"];
    setOrgPets(organization_pet);
    // setSelectedSpecie("");
  };

  const setAge = (input) => {
    if (input.length && input[0].target?.name) {
      setAgeKey(input.map(i => i.target.name));
    } else {
      setAgeKey(input);
    }
  };

  const setSize = (input) => {
    if (input.length && input[0].target?.name) {
      setSizeKey(input.map(i => i.target.name));
    } else {
      setSizeKey(input);
    }
  };

  const setSex = (input) => {
    if (input.length && input[0].target?.name) {
      setSexKey(input.map(i => i.target.name));
    } else {
      setSexKey(input);
    }
  };

  const setGroupByUuid = (e) => {
    const found = groupList.find((element) => {
      return (element as any)?.uuid === e;
    });

    if (found) {
      setSelectedGroup(found as any);
      getSpecies((found as any)?.uuid);
      setBreedList([]);
      //if((found as any).uuid)setSelectedSpecie((found as any).uuid);
    } else {
      setSelectedGroup({});
    }
  };

  const setGroup = (e) => {
    const found = groupList.find((element) => {
      return (element as any)?.name === e?.target?.value;
    });

    if (found) {
      setSelectedGroup(found as any);
      getSpecies((found as any)?.uuid);
      setBreedList([]);
      //if((found as any).uuid)setSelectedSpecie((found as any).uuid);
    } else {
      setSelectedGroup({});
    }
  };

  function updateCharacteristicCheckboxes(e, uuid) {
    if (!e.target.checked) {
      setSelectedCharacteristics(filterArray(selectedCharacteristics, uuid))
      return
    }

    setSelectedCharacteristics(selectedCharacteristics.concat([uuid]));
  };

  const setBranch = (e) => {
    const found = branchList.find((element) => element?.organization_branch?.name === e?.target?.value);
    SETBRANCH(e?.target?.value);

    if (found) {
      const updated = selectedBranch.concat([found.organization_branch?.uuid])
      setSelectedBranch(updated);
    }
  };

  const setSpecie = (e) => {
    const found = speciesList.find((element) => element?.name === e?.target?.value);

    if (found) {
      const updated = selectedSpecie.concat([found.uuid])
      setSelectedSpecie(updated);
    }
  };

  const setSpecieCheckbox = (e, uuid) => {
    if (!e.target.checked) {
      setSelectedSpecie(filterArray(selectedSpecie, uuid))
      return
    }

    setSelectedSpecie(selectedSpecie.concat([uuid]));
  };

  const setBreedBox = (e, uuid) => {
    if (!e.target.checked) {
      setSelectedBreed(filterArray(selectedBreed, uuid))
      return
    }

    setSelectedBreed(selectedBreed.concat([uuid]));
  };

  const setBranchCheckboxes = (e, uuid) => {
    if (!e.target.checked) {
      setSelectedBranch(filterArray(selectedBranch, uuid))
      return
    }

    const found = branchList.find((element) => element?.organization_branch?.uuid === uuid);

    SETBRANCH(uuid);

    if (found) {
      setSelectedBranch(selectedBranch.concat([found.organization_branch.uuid]));
    }
  };

  const setBreed = (e) => {
    const found = breedList.find((element) => element?.name === e?.target?.value);

    if (found) {
      setSelectedBreed((found as any).uuid);
    } else {
      setSelectedBreed([]);
    }
  };

  const ApplyFilters = () => {
    let org = organization_pet as any;

    if (selectedSpecie.length) {
      org["specie_uuid"] = selectedSpecie;
    } else {
      delete org["specie_uuid"];
    }

    if (selectedBreed) {
      org["breed_uuid"] = selectedBreed;
    } else {
      delete org["breed_uuid"];
    }

    if (!!selectedCharacteristics.length) {
      org["characteristic_uuid"] = selectedCharacteristics
    } else {
      delete org["characteristic_uuid"]
    }

    org.age_key = ageKey as any;
    org.sex_key = sexKey as any;
    org.size_key = sizeKey as any;
    org.foster = fosterFilter as any;

    setOrgPets(org);
    //Filter();
    setFilterAgain(true)
    mutate([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    setOpen(false);
  };

  const clearAllFilters = () => {
    setSearch("");
    setOpen(false);
    // specie
    setSelectedSpecie([]);
    setCharacteristicList([])

    setSelectedBreed([])

    // branch
    SETBRANCH("");
    setSelectedBranch([]);

    // breed
    setBreedList([]);
    setSelectedBreed([]);

    // group
    setSelectedGroup(undefined);

    // age
    setAgeKey([]);
    setSelectedAge([]);

    // size
    setSelectedSize([]);
    setSizeKey([]);

    // sex
    setSelectedSex([]);
    setSexKey([]);

    // characteristics
    setSelectedCharacteristics([]);

    // foster
    setFosterFilter(undefined);

    let org: OrganizationPet = {
      query: organization_pet.query,
      size_key: [],
      sex_key: [],
      age_key: [],
      foster: undefined
    };

    setOrgPets(org);

    //Filter(true);
    setFilterAgain(true)
  };

  const clearSingleFilter = async (name) => {
    let org = { ...organization_pet };

    setOpen(false);

    const ClearSpecie = () => {
      delete org["specie_uuid"];
      delete org["breed_uuid"];
      setSelectedSpecie([]);
      getSpecies("");
      setBreedList([]);
      setSelectedBreed([]);
    }

    switch (name) {
      case 'specie': {
        ClearSpecie();
        break
      }

      case 'breed': {
        delete org["breed_uuid"];

        setBreedList([]);
        setSelectedBreed([]);

        break
      }

      case 'group': {
        delete org["group_uuid"];

        const obj = {};
        setSelectedGroup(undefined);
        ClearSpecie();

        break
      }

      case 'age': {
        delete org["age_key"];
        org.age_key = [];
        await setAgeKey([]);
        await setSelectedAge([]);
        //setSelectedSex([]);

        //setSelectedSize([]);

        break
      }

      case 'size': {
        delete org["size_key"];
        org.size_key = [];
        setSizeKey([]);
        setSelectedSize([]);

        break
      }

      case 'sex': {
        delete org["sex_key"];
        org.sex_key = [];
        setSexKey([]);
        setSelectedSex([]);

        break
      }

      case 'branch': {
        SETBRANCH("");
        setSelectedBranch([]);

        break
      }

      case 'foster': {
        setFosterFilter(undefined)

        break
      }

      case 'characteristic': {
        delete org["characteristic_uuid"]
        org.characteristic_uuid = null;
        setSelectedCharacteristics([])

        break
      }
    }

    await setOrgPets(org);

    setFilterAgain(true);

  };

  useEffect(() => {
    if (filterAgain) {
      Filter(false);
      setFilterAgain(false);
    }
  }, [filterAgain, orgPets])


  const getFitersNumber = () => {
    let num = 0;

    if (selectedBranch.length) num++

    if (selectedGroup !== undefined) num++

    if (selectedBreed.length) num++

    if (selectedSpecie.length) num++

    if (ageKey.length) num++

    if (sexKey.length) num++

    if (sizeKey.length) num++

    if (selectedCharacteristics.length) num++

    return num;
  };

  const filterArray = (input, exclude) => {
    return input.filter(x => x !== exclude)
  }

  const filterDefaultItems = (input: string[], defaultItems: string, reset?: boolean) => {
    const result = input?.length && !reset ? input : defaultItems?.length ? defaultItems.split('|') : null

    return result
  }

  function buildCharacteristics(input): CharacteristicGroupItem[] {
    if (!!!input?.length) return []

    const groups = {}

    input.map(c => {
      const { uuid, name, group } = c

      if (!groups[group.uuid]) {
        groups[group.uuid] = {
          ...group,
          characteristics: []
        }
      }

      groups[group.uuid].characteristics.push({ uuid, name })
    })

    return Object.values(groups)
  }

  const updateGroupLabel = (name: string) => {
    const updated = name.endsWith('l') ? name : name + 'al'

    return updated
  }

  const characteristicsCheckboxes = (
    <CheckboxScrollMenu data-testId="container-characteristics-list">
      {characteristicList?.map((group, index) => {

        const ICONSLIST = {
          MEDICAL: require(`../../resources/icons/MEDICAL.svg`),
          ENVIRONMENT: require(`../../resources/icons/ENVIRONMENT.svg`),
          BEHAVIOR: require(`../../resources/icons/BEHAVIOR.svg`)
        }


        const icon = ICONSLIST[group.name.toLocaleUpperCase()]

        return (
          <div key={"chactrst-" + index}
            style={{
              display: "flex",
              flexDirection: "column"
            }}>

            <h3 style={{ color: characteristicsGroupColors[group.name.toLocaleLowerCase()] }}>
              <img
                src={icon}
                alt=""
              />
              <span>{updateGroupLabel(group.name)}</span>
            </h3>
            {group.characteristics?.map((item, i) => (
              <Checkbox
                key={item.uuid}
                data-testId={`characteristics-${updateGroupLabel(group.name.toLocaleLowerCase())}-option-${i}`}
                checked={selectedCharacteristics.includes(item?.uuid)}
                onChange={(e) => {
                  updateCharacteristicCheckboxes(e, item?.uuid);
                }}
              >
                {item?.name}
              </Checkbox>
            ))}
          </div>
        )
      })}
    </CheckboxScrollMenu>
  );

  const fosterCheckboxes = (
    <div className="box">
      <Row gutter={[30, 16]}>
        <Col xs={12} sm={8} md={6} lg={24} className="checkboxContainer">
          <Checkbox checked={fosterFilter} onChange={() => setFosterFilter(true)}>
            In foster
          </Checkbox>
        </Col>
        <Col xs={12} sm={8} md={6} lg={24} className="checkboxContainer">
          <Checkbox checked={fosterFilter === false} onChange={() => setFosterFilter(false)}>
            In shelter
          </Checkbox>
        </Col>
        <Col xs={12} sm={8} md={6} lg={24} className="checkboxContainer">
          <Checkbox checked={fosterFilter === undefined} onChange={() => setFosterFilter(undefined)}>
            All
          </Checkbox>
        </Col>
      </Row>
    </div>
  );

  const branchCheckboxes = (
    <CheckboxScrollMenu data-testId="container-location-list">
      {!branch_uuid
        ? branchList?.map((item, i) => {
          return (
            <Checkbox
              data-testId={`location-option-${i}`}
              key={item?.organization_branch?.uuid}
              checked={selectedBranch.includes(item?.organization_branch?.uuid)}
              onChange={(e) => {
                setBranchCheckboxes(e, item?.organization_branch?.uuid);
              }}
            >
              {item?.organization_branch?.name}
            </Checkbox>
          );
        })
        : branchList
          ?.filter((name) =>
            (branch_uuid as string).split("|").includes(name?.organization_branch?.uuid)
          )
          ?.map((item, i) => {
            return (
              <Checkbox
                data-testId={`checkbox-filter-location-${i}`}
                key={item?.organization_branch?.uuid}
                checked={selectedBranch.includes(item?.organization_branch?.uuid)}
                onChange={(e) => {
                  setBranchCheckboxes(e, item?.organization_branch?.uuid);
                }}
              >
                {item?.organization_branch?.name}
              </Checkbox>
            );
          }
          )
      }
    </CheckboxScrollMenu>
  );

  const newSpecieCheckboxes = (
    <CheckboxScrollMenu data-testId={`container-filter-specie`}>
      {!specie_uuid
        ? speciesList?.map((item, i) => {
          return (
            <Checkbox
              data-testId={`checkbox-filter-specie-${i}`}
              key={item.uuid}
              checked={selectedSpecie.includes(item.uuid)}
              onChange={(e) => {
                setSpecieCheckbox(e, item.uuid);
              }}
            >
              {item.name}
            </Checkbox>
          );
        })
        : speciesList
          ?.filter((name) => (specie_uuid as string)?.split("|").includes(name.uuid))
          ?.map((item, i) => {
            return (
              <Checkbox
                data-testId={`checkbox-filter-specie-${i}`}
                key={item.uuid}
                checked={selectedSpecie.includes(item.uuid)}
                onChange={(e) => {
                  setSpecieCheckbox(e, item.uuid);
                }}
              >
                {item.name}
              </Checkbox>
            );
          }
          )
      }
    </CheckboxScrollMenu>
  );

  const breedCheckboxes = (
    <CheckboxScrollMenu data-testId={`container-breed`} className="box">
      {breedList?.map((item, i) => {
        return (
          <Checkbox
            data-testId={`checkbox-filter-breed-${i}`}
            key={item.uuid}
            checked={selectedBreed.includes(item.uuid)}
            onChange={(e) => {
              setBreedBox(e, item.uuid);
            }}
          >
            {item.name}
          </Checkbox>
        );
      })}
    </CheckboxScrollMenu>
  );

  const sexCheckboxes = (
    <div className="box" data-testId={`container-filter-sex`}>
      <Row gutter={[30, 16]}>
        {sexList?.map((item, index) => {
          return (
            <Col xs={12} sm={8} md={6} lg={24} className="checkboxContainer" key={"checksex" + index}>
              <Checkbox
                data-testId={`checkbox-filter-sex-${index}`}
                name={item.name.toUpperCase()}
                checked={selectedSex.includes(item.name.toUpperCase())}
                onChange={(e) => {
                  if (!e.target.checked) {
                    const updated = filterArray(selectedSex, item.name.toUpperCase())

                    setSelectedSex(updated)
                    setSex(updated);

                    return
                  }

                  const updated = selectedSex.concat([item.name.toUpperCase()])

                  setSex(updated);
                  setSelectedSex(updated);
                }}
              >
                {item.name}
              </Checkbox>
            </Col>
          );
        })}
      </Row>
    </div>
  );

  const ageCheckboxes = (
    <div className="box">
      <Row gutter={[30, 16]} data-testId={`container-filter-age`}>
        {ageList?.map((item, index) => {
          return (
            <Col xs={12} sm={8} md={6} lg={24} className="checkboxContainer" key={"checksex" + index}>
              <Checkbox
                data-testId={`checkbox-filter-age-${index}`}
                name={item.name.toUpperCase()}
                checked={selectedAge.includes(item.name.toUpperCase())}
                onChange={(e) => {
                  if (!e.target.checked) {
                    const updated = filterArray(selectedAge, item.name.toUpperCase())

                    setSelectedAge(updated)
                    setAge(updated);

                    return
                  }

                  const updated = selectedAge.concat([item.name.toUpperCase()])

                  setAge(updated);
                  setSelectedAge(updated);
                }}
              >
                {item.name}
              </Checkbox>
            </Col>
          );
        })}
      </Row>
    </div>
  );

  const sizeCheckboxes = (
    <div className="box">
      <Row gutter={[30, 16]} data-testId="container-filter-size">
        {sizeList?.map((item, index) => {
          return (
            <Col xs={12} sm={8} md={6} lg={24} className="checkboxContainer" key={"checksex" + index}>
              <Checkbox
                data-testId={`checkbox-filter-size-${index}`}
                name={item.key}
                checked={selectedSize.includes(item.key)}
                onChange={(e) => {
                  if (!e.target.checked) {
                    const updated = filterArray(selectedSize, item.key)

                    setSelectedSize(updated)
                    setSize(updated);

                    return
                  }

                  const updated = selectedSize.concat([item.key])

                  setSize(updated);
                  setSelectedSize(updated);
                }}
              >
                {item.name}
              </Checkbox>
            </Col>
          );
        })}
      </Row>
    </div>
  );

  return (
    <ShelterPageStyle style={{
      display: "flex",
      justifyContent: "center",
      width: "100%"
    }}>
      <div style={{
        padding: p ? Number(p) : 16,
        width: "100%"
      }}>
        <div style={{
          display: lg ? "none" : (xs && shelter_uuid) ? "block" : "none"
        }}>
          <div
            style={{
              cursor: "pointer",
              marginBottom: "16px",
              height: "40px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              boxShadow: "0px 2px 8px rgba(36, 55, 78, 0.06)"
            }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <div
              style={{
                backgroundColor: "#E52E6B",
                height: "16px",
                width: "16px",
                borderRadius: "100%",
                color: "#FFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "12px",
                fontWeight: 500,
                marginTop: "4px",
                marginRight: "4px"
              }}
            >
              {getFitersNumber()}
            </div>
            <div style={{
              fontWeight: 500,
              fontSize: "16px"
            }}>Filters</div>
            <img
              src={require("./icon.svg")}
              style={{
                marginTop: "9px",
                marginLeft: "4px",
                transform: open ? "rotate(180deg)" : ""
              }}
            />
          </div>

          <div style={{
            display: lg ? "none" : (xs && open) ? "block" : "none"
            /*display: { lg: "none", xs: open ? "block" : "none" }*/
          }}>
            {(((branch_uuid as string)?.split("|").length !== 1 && branchList.length > 0 && !!!nl) ||
              (!branch_uuid && branchList.length > 0 && !!!nl)) && (
                <div>
                  <div className="titleBox">
                    <span>Location</span>
                  </div>
                  {branchCheckboxes}
                </div>
              )}

            {characteristicList.length ?
              <div>
                <div className="titleBox">
                  <span>Pet Characteristics</span>
                </div>
                {characteristicsCheckboxes}
              </div> : null
            }

            {al_show ? (
              <div>
                <div className="titleBox">
                  <span>Care Status</span>
                </div>
                {fosterCheckboxes}
              </div>
            ) : null}

            {(specie_uuid as string)?.split("|").length !== 1 && speciesList.length > 1 && (
              <div>
                <div className="titleBox">
                  <span>Species</span>
                </div>
                {newSpecieCheckboxes}
              </div>
            )}

            {nb !== "true" && breedList.length > 0 && (
              <div>
                <div className="titleBox">
                  <span>Breed</span>
                </div>
                {breedCheckboxes}
              </div>
            )}

            <div className="titleBox">
              <span>Sex</span>
            </div>
            {sexCheckboxes}

            <div className="titleBox">
              <span>Age</span>
            </div>
            {ageCheckboxes}

            <div className="titleBox">
              <span>Size</span>
            </div>
            {sizeCheckboxes}

            <div className="mobileActions">
              <button className="secondary" onClick={clearAllFilters}>
                Clean all filters
              </button>
              <button className="primary" onClick={ApplyFilters}>
                Apply filters
              </button>
            </div>
          </div>
        </div>
        <div style={{
          display: (lg && shelter_uuid) ? "block" : (xs) ? "none" : "none"
          /*display: { lg: shelter_uuid ? "block" : "none", xs: "none" }*/
        }}>
          <Row
            gutter={[16, 16]}>
            <div
              data-testId="container-search"
              style={{
                width: "300px",
                height: "57px",
                display: "flex",
                alignItems: "center"
              }}>
              <Input
                data-testId="input-search"
                value={search}
                name="search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                style={{
                  height: "46px",
                  borderRadius: "16px"
                }}
                placeholder="Search by pet name or ID"
                onPressEnter={() => {
                  Filter();
                }}
                suffix={
                  <div
                    data-testId="button-search"
                    onClick={() => {
                      Filter();
                    }}
                    style={{
                      cursor: "pointer",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#E52E6B",
                      borderRadius: "50%",
                      color: "#FFFF"
                    }}
                  >
                    <img src={require("../../resources/search.svg")} />
                  </div>
                }
              />
            </div>

            <div style={{
              flex: "1"
            }} />

            {(((branch_uuid as string)?.split("|").length > 1 && branchList.length > 0 && !nl) ||
              (!branch_uuid && branchList.length > 0 && !nl)) && (
                <Col>
                  <DropDownButton
                    data-testId="dropdown-location"
                    hasFilter={!!selectedBranch.length}
                    width="275px"
                    title="Location"
                    onApply={ApplyFilters}
                    onClear={() => {
                      clearSingleFilter("branch");
                    }}
                  >
                    {branchCheckboxes}
                  </DropDownButton>
                </Col>
              )}

            {characteristicList.length ?
              <Col>
                <DropDownButton
                  data-testId="dropdown-characteristics"
                  hasFilter={!!selectedCharacteristics.length}
                  width="275px"
                  title="Pet Characteristics"
                  onApply={ApplyFilters}
                  onClear={() => { clearSingleFilter("characteristic") }}
                >
                  {characteristicsCheckboxes}
                </DropDownButton>
              </Col> : null
            }

            {al_show ? (
              <Col>
                <DropDownButton
                  data-testId="dropdown-care-status"
                  hasFilter={fosterFilter !== undefined}
                  width="275px"
                  title="Care Status"
                  onApply={ApplyFilters}
                  onClear={() => { clearSingleFilter("foster") }}
                >
                  {fosterCheckboxes}
                </DropDownButton>
              </Col>
            ) : null}

            {(specie_uuid as string)?.split("|").length !== 1 && speciesList.length > 1 && (
              <Col>
                <DropDownButton
                  data-testId="dropdown-species"
                  hasFilter={!!selectedSpecie.length}
                  width="275px"
                  title="Species"
                  onApply={ApplyFilters}
                  onClear={() => {
                    clearSingleFilter("specie");
                  }}
                >
                  {newSpecieCheckboxes}
                </DropDownButton>
              </Col>
            )}
            {nb !== "true" && breedList.length > 0 && (
              <Col>
                <DropDownButton
                  data-testId="dropdown-breed"
                  hasFilter={!!selectedBreed.length}
                  width="275px"
                  title="Breed"
                  onApply={ApplyFilters}
                  onClear={() => {
                    clearSingleFilter("breed");
                  }}
                >
                  {breedCheckboxes}
                </DropDownButton>
              </Col>
            )}
            <Col>
              <DropDownButton
                data-testId="dropdown-sex"
                hasFilter={!!sexKey.length}
                width="275px"
                title="Sex"
                onApply={ApplyFilters}
                onClear={() => {
                  clearSingleFilter("sex");
                }}
              >
                {sexCheckboxes}
              </DropDownButton>
            </Col>
            <Col>
              <DropDownButton
                data-testId="dropdown-age"
                hasFilter={!!ageKey.length}
                width="275px"
                title="Age"
                onApply={ApplyFilters}
                onClear={() => {
                  clearSingleFilter("age");
                }}
              >
                {ageCheckboxes}
              </DropDownButton>
            </Col>
            <Col>
              <DropDownButton
                data-testId="dropdown-size"
                hasFilter={!!sizeKey.length}
                width="275px"
                title="Size"
                onApply={ApplyFilters}
                onClear={() => {
                  clearSingleFilter("size");
                }}
              >
                {sizeCheckboxes}
              </DropDownButton>
            </Col>
            <Col>
              <button data-testId="action-clear-all" className="clearSecondary" onClick={clearAllFilters}>
                Clear All
              </button>
            </Col>
          </Row>
        </div>

        <div
          data-testId="mobile-container-search"
          style={{
            width: "100%",
            height: "57px",
            marginBottom: "16px",
            display: (lg) ? "none" : (xs && shelter_uuid) ? "block" : "none",
            /* display: { lg: "none", xs: shelter_uuid ? "block" : "none" },*/
            alignItems: "center"
          }}>
          <Input
            data-testId="input-search"
            value={search}
            name="search2"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            style={{
              height: "46px",
              borderRadius: "16px"
            }}
            placeholder="Search by pet name or ID"
            onPressEnter={() => {
              Filter();
            }}
            suffix={
              <div
                data-testId="button-search"
                onClick={() => {
                  Filter();
                }}
                style={{
                  cursor: "pointer",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#E52E6B",
                  borderRadius: "50%",
                  color: "#FFFF"
                }}
              >
                <img src={require("../../resources/search.svg")} />
              </div>
            }
          />
        </div>

        <Row gutter={[g ? Number(g) : 16, g ? Number(g) : 16]}>
          {shelter_uuid && data?.map((item, index) => {
            return (
              <Col key={"colp" + index} xs={12} md={8} lg={6}>
                <Card data-testId={`container-pet-card-${index}`} tracking_id={trackerUUID} data={item} />
              </Col>
            );
          })}

          {loading &&
            [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].map((item, index) => {
              return (
                <Col key={"colp" + index} xs={12} md={8} lg={6}>
                  <Card tracking_id={""} data={item} />
                </Col>
              );
            })}
        </Row>
        {(data?.length === 0 || !shelter_uuid) && (
          <div style={{
            width: "100%",
            minHeight: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column"
            }}>
              <div style={{
                fontWeight: 500,
                fontSize: "18px",
                color: "rgb(36, 55, 78)",
                textAlign: "center"
              }}>
                We didn't find any pet results for this location.
              </div>
              <div style={{
                color: "rgb(125, 139, 155)",
                fontSize: "16px",
                marginTop: "8px",
                marginBottom: "16px",
                textAlign: "center"
              }}>
                Try updating the search location to find available pets or visit
              </div>
              {Number(getFitersNumber()) > 0 && (
                <button className="clearSecondary" onClick={clearAllFilters}>
                  Clear All
                </button>
              )}
            </div>
          </div>
        )}
        <div style={{
          marginTop: "56px",
          marginBottom: "56px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          {lastSearch === 12 && (
            <div
              data-testId="action-load-more-pets"
              className="secondaryBtn"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                height: "37px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer"
              }}
              onClick={LoadMore}
            >
              Load more pets
            </div>
          )}
        </div>
      </div>
    </ShelterPageStyle>
  );
};

ShelterPage.propTypes = {
  // your expected props
};

ShelterPage.defaultProps = {
  // your default props
};

export default ShelterPage;
