import React, { Dispatch, SetStateAction, useContext } from "react";

interface IThemeContext {
    sessionKey: string;
    setSessionKey?: Dispatch<SetStateAction<string>>;
  }
  
  const defaultState = {
    sessionKey: '',
  };
  
  export const SessionContext = React.createContext<IThemeContext>(defaultState);

  export const useSession = () => useContext(SessionContext);